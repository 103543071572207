import React from "react";
import { Container, Row, Card, CardDeck, Button } from "react-bootstrap";

import {
  whatsAppGroup,
  liveStreamLink,
  handstandHappyHourSignUp,
} from "../../constants";

import HandstandHappyHourImage from "../../img/HandstandHappyHour.png";

const Classes = () => {
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <div className="classesImage headerImage"></div>
      <Container fluid style={{ backgroundColor: "#90a9c5" }}>
        <h2 style={{ textAlign: "center", paddingTop: "50px" }}>
          Weekly Classes
        </h2>
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Button variant="secondary" href={liveStreamLink}>
            Join the Live Stream
          </Button>
        </div>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <CardDeck>
            <Card>
              <Card.Body>
                <Card.Title>Early Bird Flow</Card.Title>
                <Card.Subtitle>Tuesdays, 7.30 - 8.30</Card.Subtitle>
                <Card.Text>
                  <br />
                  <a href="https://www.yogastudiozuerich.ch/">
                    Yoga Studio Zürich
                  </a>
                  <br /> Zwinglistr. 8, Zurich <br />
                  <br /> Single Class: 25 CHF
                  <br /> 10x Abo: 200 CHF
                  <br /> Live Stream: 15 CHF <br />
                  <br />
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">
                  Outdoors in Summer: <br />
                  <a href={whatsAppGroup} style={{ color: "#90a9c5" }}>
                    Join the WhatsApp group
                  </a>
                </small>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Lunch Flow</Card.Title>
                <Card.Subtitle>Wednesdays, 12.15 - 13.20</Card.Subtitle>
                <Card.Text>
                  <br /> <a href="https://www.yogaamfluss.ch/">Yoga am Fluss</a>
                  <br /> Hardturmstr. 68A, Zurich <br />
                  <br /> Single Class: 25 CHF
                  <br /> 10x Abo: 200 CHF
                  <br /> Live Stream: 15 CHF <br />
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </CardDeck>
        </Row>
        <h2 style={{ textAlign: "center", marginTop: "50px" }}>Workshops</h2>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          {/* <p>
            No workshops planned at the moment.{" "}
            <a href={mailToLink}>Reach out</a> if you'd like to organize one.
          </p> */}
          <CardDeck style={{ maxWidth: "600px" }}>
            <Card>
              <Card.Img src={HandstandHappyHourImage} variant="top" />
              <Card.Body>
                <Card.Title>Handstand Happy Hour 2024</Card.Title>
                <Card.Subtitle>
                  May-October / 3x a month on Fridays, 11.45-12.45
                </Card.Subtitle>
                <Card.Text>
                  <p>Location: Wipkingerpark OR Online</p>
                  <p>
                    Join the Handstand Happy Hour for a weekly community outdoor
                    inversion practice. Consistency is key when practicing
                    headstand, handstand & co. The Handstand Happy Hour is your
                    opportunity to build up a regular inversion practice and
                    meet like-minded upside-down enthusiasts. Plus: you can get
                    an hour of uplifting outdoor training into your week to
                    increase your level of endorphine.
                  </p>
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Button variant="secondary" href={handstandHappyHourSignUp}>
                  More info & sign up
                </Button>
              </Card.Footer>
            </Card>
            {/*<Card>
              <Card.Img src={YogaBeatsImage} variant="top" />
              <Card.Body>
                <Card.Title>YOGABEATS</Card.Title>
                <Card.Subtitle>Monday, October 30th, 18.30-20.30</Card.Subtitle>
                <Card.Text>
                  <p>
                    Location:{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.google.com/maps/place/naanu/@47.3908333,8.5091667,15z/data=!4m2!3m1!1s0x0:0x88056ba039fd8da4?sa=X&ved=2ahUKEwjh9s_zvuCAAxVr_rsIHZEvBcUQ_BJ6BAg9EAA&ved=2ahUKEwjh9s_zvuCAAxVr_rsIHZEvBcUQ_BJ6BAhcEAg"
                    >
                      Naanu, Zurich-West
                    </a>
                  </p>
                  <p>After-work yoga & electronic beats with Albert & Lisa.</p>
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Button variant="secondary" href={yogaBeatsSignUp}>
                  More info & sign up
                </Button>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Img src={IntroToYogaImage} variant="top" />
              <Card.Body>
                <Card.Title>Introduction to Yoga</Card.Title>
                <Card.Subtitle>Sunday, January 21, 09.00-11.30</Card.Subtitle>
                <Card.Text>
                  <p>
                    Location:{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://g.co/kgs/Sk1Q63"
                    >
                      Yoga Studio Zurich
                    </a>
                  </p>
                  <p>
                    A workshop to get an introduction to yoga as a holistic
                    practice for beginners: benefits of asanas (physical
                    postures), body awareness, breathing techniques, simple
                    methods for guided meditation.
                  </p>
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Button variant="secondary" href={introToYogaSignUp}>
                  More info & sign up
                </Button>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Img src={BeyondAsanaImage} variant="top" />
              <Card.Body>
                <Card.Title>Beyond Asana</Card.Title>
                <Card.Subtitle>Sunday, January 21, 12.00-15.00</Card.Subtitle>
                <Card.Text>
                  <p>
                    Location:{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://g.co/kgs/Sk1Q63"
                    >
                      Yoga Studio Zurich
                    </a>
                  </p>
                  <p>
                    A workshop to learn about yoga beyond asana: the eight limbs
                    of yoga, yoga philosophy, prana vayus, pranayama techniques,
                    and meditation.
                  </p>
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Button variant="secondary" href={beyondAsanaSignUp}>
                  More info & sign up
                </Button>
              </Card.Footer>
            </Card>*/}
          </CardDeck>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Classes;

const welcomepost = {
  title: "Welcome to my new website",
  subtitle: "",
  date: "August 14 2020",
  sections: [
    "This is the first post on this blog. I have never had a blog and now it's here. And I even built it myself. I intend to use this blog to report about my yoga journey. Mostly it will be just a lot of my random thoughts around yoga and life that are bubbeling up from time to time.",
    "As some of you know, I am a software engineer when I am not teaching yoga. And I am pretty excited to have built and deployed this website for my yoga offerings within less than 24h. The website is based on the create-react-app boilerplate and uses redux and saga to make the magic happen behind the scenes. The layout has been implemented using react-bootstrap components. For some of you this might sound like a lot of words that I just chained up behind each other, I know. Sorry about that! Hey, but if you happen to need someone build you a yoga website as simplicistic and minimalistic as this one, hit me up.",
    "I know the page is far from perfect (and I still want to add a lot of things like routing) but for me it was a nice little exercise to build a responsive website from scratch, make it easy to adjust & deploy it within a reasonable amount of time. This might be hard to comprehend, but I actually enjoyed the process.",
    "Oh and by the way: The beautiful pictures on this page have all been done by Frederic (@yogasavauge).",
    "Lots of love,",
    "Lisa",
  ],
};

const patience = {
  title: "Patience ✨",
  subtitle: "",
  date: "August 14 2020",
  sections: [
    "I would consider myself a person with a decent amount of patience - except when it comes to my own body’s healing process 🙈. Then I get extremly impatient - and I get frustrated quickly about the fact that I am unable to do the things that I love. After a surf accident a month ago, I am currently struggeling with this situation again - and I am taking this opportunity to talk about how to prevent injuries and practice safely.",
    "I have quite a history of «smaller» injuries, muscles strains, bone sprains, and so on. After all, I am a pretty clumsy person 😆 and clumsiness & being active doesn’t always go very well :). Every injury in the past has forced me to slow down for a while, take a break from being active & give my body rest. Very often, I’ve had injuries in the worst of situations... but looking back, I often needed a break more than I wanted to admit.",
    "I have learned the hard way over the years that not every body needs the same amount of breaks and care. Some people can do crazy things without stopping... but not me. My body typically needs more breaks than I am allowing it to take.",
    "In yoga, knowing your body and its limits is in my opinion the most important part of the practice. Every body is different and yours is as unique as everyone elses. Be curious about it, challenge it but also know when to stop and take a break.",
    "1️⃣ The first step in avoiding injuries is being honest with yourself & not forcing your body into poses or transitions it’s not ready yet - or won’t every be ready. Be patient with your body!",
    "2️⃣ Second step is to start small & build up. Try the easy pose first and master it, before you attempt the more difficult ones. Use modifications & props! Don’t let your ego come in the way just because the person next to you (or on your insta feed) can do it. Every body is different!",
    "3️⃣ The third step is to be very attentive and listen to your body while practicing: Does this pose feel good? Where do you feel tension? Where do you feel opening? Explore your body & listen to its signals. I am sure you will discover wonderful things while being safe in your practice.",
    "Lots of love,",
    "Lisa",
  ],
};

const fear = {
  title: "Fear ✨",
  subtitle: "",
  date: "December 30 2020",
  sections: [
    "I am many things but I am definitely not fearless. I am actually afraid of many things including heights, spiders & the forces of nature. I get triggered quite easily but I have made it a habit to never run away from the things that I fear. I try to approach them with cautious curiosity ✨",
    "How does fear relate to yoga? There are various triggers in a yoga practice that can result in fear, from which I believe the biggest three are...",
    "1️⃣ fear of (potential) pain",
    "2️⃣ fear of failure",
    "3️⃣ fear of the unknown",
    "I have experienced all of them myself during practice. I have for example not practiced splits for quite a while now because a few years ago I had torn a hamstring in this pose. I am very cautious still with headstands because of a recent neck injury, and so on. I fear those asanas because I connect them with previous painful experiences. This type of fear is the easiest to face: know your body & listen to it. Don’t always go to the maximum and explore the limits of your body with cautious(!) curiosity 💛",
    "The second fear - fear of failure - is trickier because it relates to how we see ourselves and how attached we are to that. Honestly, in yoga there is no failing. The only failing would be not to do yoga 😉. If it feels good in your body, if it feels good in your mind, then there is no need to worry. However, the fear of failure might be triggering you to move too deep or too fast into a pose... that’s worrysome. Recognize this fear early and hold against it. Yoga is not a competition - whether against yourself nor against anybody else 💛",
    "The last fear is one that can be productive when we face it. Anything can be unknown - a new asana, a new sensation in your body, a new world that opens up in your mind. We are trained to be doubtful at first with anything that is unknown and that is a good thing. But we can only expand in our capacity to grow mentally and physically if we try new things, if we get out of our comfort zone. Therefore, I encourage you to embrace the unknown, experiment with new things in your practice & then observe 💛",
    "Lots of love,",
    "Lisa",
  ],
};

const allowance = {
  title: "Why are you always so hard on yourself?",
  date: "April 4 2021",
  sections: [
    "Allow yourself to make mistakes. Allow yourself to not always be perfect.",

    "Allow yourself to not get it right the first time. Allow yourself to learn and grow in your own speed.",

    "Allow yourself to be happy. Allow yourself to cry and be sad.",

    "Allow yourself to have self doubt. Allow yourself to be proud.",

    "Allow yourself to trust the process. Allow yourself to just be. ✨",

    "—",

    "I am my own biggest critic. I struggle to shut down the constant questioning of my abilities, of my being, of what I have achieved and of what I haven‘t.",

    "I am writing these lines after an intense meditation practice where I wasn‘t able to calm down & get into it. My thoughts were going round and round, my heart was beating fast, my throat felt tense. On top of that I was beating myself up for not being able to get into the practice.",

    "And then I suddenly thought „why am I doing this? why can‘t I allow myself to just be here struggling?“ and so I actively let go of any expectations. I just sat there & allowed my breath to do whatever. And by allowing myself to relax, I slowly but surely got into the practice & recited the lines above in my head.",

    "Allow yourself to just be. ✨",
  ],
};

const practice = {
  title: "How often do you practice yoga?",
  date: "May 31 2021",
  sections: [
    "As a teacher I often get asked how often I practice yoga. This sounds like a pretty normal small talk question, right? But let me tell you: for a yogi it’s an odd one 🙈. It’s as if someone would ask you how often do you breath or how often does your heart beat per day... no one can really answer that.",

    "Yoga is a continous practice for me. It’s not just showing up on the mat, moving and exploring sensations in the body, or sitting down for a meditation session. It’s also about how you meet yourself and others every day, how you sense the world around you and how you react to it.",

    "So I’m assuming what people really want to know when they ask that question is how often I practice asanas or how often I meditate. I do have a regular practice, but I don’t *force* myself to a daily practice. I’ve heard many teachers say that you need to practice asanas and meditation every day in order to be a good teacher. I don’t believe in that. I believe in balance!",

    "I also believe if we live by such strict rules - and expect others to do the same - they will literally eat us up at times. Strict rules might work for some people, but they might be a burden for others. And that’s why I will never preach the “yoga every damn day” mantra in the sense that you should practice asanas every day.",

    "Instead, try to practice kindness towards yourself and others every day. Practice mindfulness in every moment of your life. Practice being present & aware of your surroundings. Does that sound easier than showing up on your mat every day? Let me tell you: it’s the real challenge of your yoga practice ✨ all the rest is just the circus!",
  ],
};

const surrender = {
  title: "Surrender ✨",
  date: "March 12 2022",
  sections: [
    "Some days you will wake up feeling like you can conquer the world & achieve anything you want 💪",

    "And on other days you’ll only feel the heaviness of all the problems to be fixed and all the things to be done - unable to move at all.",

    "Energy comes and goes in waves. In yoga, we aim to listen attentively to the mind and the body to recognize and honor the energy that is available to us during the practice.",

    "Some days you’ll feel like handstands and sun salutations over and over again (yang). Other days you’ll feel like savasana and restorative poses (yin).",

    "And that’s okay - even more so: it is extremely important to know what your body & mind needs. Through yoga we learn to listen. We learn to allow ourselves to surrender to what our body and mind needs.",

    "So whatever you need today, I wish that you are able to recognize and honor your unique waves of energy (prana) - without force or judgement.",
  ],
};

export default [surrender, practice, allowance, fear, patience, welcomepost];

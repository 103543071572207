import { combineReducers } from "redux";
import {
  OPEN_NEWSLETTER_DIALOG,
  CLOSE_NEWSLETTER_DIALOG,
  SET_BUSY_STATE
} from "../actions";

function newsletterDialogVisibility(state = false, action) {
  switch (action.type) {
    case OPEN_NEWSLETTER_DIALOG:
      return true;
    case CLOSE_NEWSLETTER_DIALOG:
      return false;
    default:
      return state;
  }
}

function busyState(state = false, action) {
  switch (action.type) {
    case SET_BUSY_STATE:
      return action.state;
    default:
      return state;
  }
}

const reducers = combineReducers({
  newsletterDialogVisibility,
  busyState
});

export default reducers;

import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./common/header";
import Footer from "./common/footer";

import NewsletterDialog from "./common/newsletter";

import Home from "./pages/home";
import Classes from "./pages/classes";
import Retreats from "./pages/retreats";
import Videos from "./pages/videos";
import OnlineClasses from "./pages/online-classes";
import CardioVideos from "./pages/yoga-cardio";
import Blog from "./pages/blog";
import About from "./pages/about";

const Content = () => {
  const newsletterDialogVisibility = useSelector(
    (state) => state.newsletterDialogVisibility
  );
  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }

  return (
    <Router>
      <Header />
      {newsletterDialogVisibility && <NewsletterDialog />}
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/classes">
          <Classes />
        </Route>
        <Route path="/retreats">
          <Retreats />
        </Route>
        <Route path="/videos">
          <Videos />
        </Route>
        <Route path="/online-classes">
          <OnlineClasses />
        </Route>
        <Route path="/cardio-videos">
          <CardioVideos />
        </Route>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/about">
          <About />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
};

export default Content;

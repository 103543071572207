import React from "react";
import { Container, Row } from "react-bootstrap";

const CardioVideos = () => {
  window.scrollTo(0, 0);

    return (
      <Container
        fluid
        className="content-container"
        style={{ height: "100vh" }}
      >
        <Row
          style={{
            paddingTop: "50px",
            position: "relative",
            height: "100%",
          }}
        >
          <iframe
            title="vimeo-videos"
            src="https://vimeo.com/showcase/7957740/embed"
            allowFullScreen
            frameBorder="0"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </Row>
      </Container>
    );
}

export default CardioVideos;

export const newsletterSignUp = "http://eepurl.com/hbxv5T";

export const signUpYogaHike =
  "https://docs.google.com/forms/d/e/1FAIpQLSfsQKs6azG_SRXGeInEwM_Mye3eKUdFhldf-7zDYG8b_3Cz7A/viewform";

export const liveStreamLink = "http://meet.jit.si/HathaLisaOnlineYoga";

export const springChallenge =
  "https://docs.google.com/forms/d/e/1FAIpQLSd-2Aqx7w498n9CUfYbFv8lch2lKq2RwfA2fhZ9PYD4bsoJDw/viewform";

export const signUpYogaMountainRetreat =
  "https://docs.google.com/forms/d/e/1FAIpQLSdEEXpCzh51QQmv92kxDHWHEhMSGkHRJjbdidIrM7KWCnANrA/viewform";

export const inversionsWorkshop =
  "https://docs.google.com/forms/d/e/1FAIpQLSePwfIX2c5GXUYmPacS97iD4z_kVlHPMxyPT8Nc3wTazjtPXg/viewform";

export const connectionRetreat =
  "https://docs.google.com/forms/d/e/1FAIpQLSeCdNxGY5E13_BaebW2mn0Hvzx2KHYmeO0o0bNUS7FEXr3RFQ/viewform";

export const whatsAppGroup = "https://chat.whatsapp.com/IAlXOdP8LOuHHElEyuwOC6";

export const mailToLink = "mailto:hathalisa@outlook.com";

export const handstandWorkshop =
  "https://docs.google.com/forms/d/e/1FAIpQLSeQ6iP3tJEifE8MPztVB7oD0aU8A63Tnb4Y2Um00ZYb3yPCUQ/viewform";

export const signUpAlpineYogaRetreat =
  "https://docs.google.com/forms/d/e/1FAIpQLScWHuvAawV3mrCRbB9iTnHtEtQDWsHnVk0Y3GInfWJVc1l5kA/viewform";

export const yogaBeatsSignUp =
  "https://eventfrog.ch/de/p/sport-fitness/yoga/yogabeats-after-work-yoga-electronic-beats-7112462120755623759.html";

export const beyondAsanaSignUp =
  "https://docs.google.com/forms/d/e/1FAIpQLSd00v8BYrqnVsk2pNcp3DZHDXWFCTJHeF_QPqZRgIn25aI-3A/viewform";

export const introToYogaSignUp =
  "https://docs.google.com/forms/d/e/1FAIpQLScCj5LLW4MZXaWwE0QlpTAVHEbJuX3nSRDxEdAzZeuciiQOeQ/viewform";

export const yogaSnowSignUp =
  "https://docs.google.com/forms/d/e/1FAIpQLSfxr1EyMoVUGNlIG4fPg-MVsH33DrmdyuMpsmYqGWKWhqrw3g/viewform";

export const yogaHikeSignUp =
  "https://docs.google.com/forms/d/e/1FAIpQLSc0FPZCXG8OZ8q3JOOVnRYFbj2-qBW9wJFhwR-p41zQdSDAiQ/viewform";

export const yogaMountainRetreatSignUp =
  "https://docs.google.com/forms/d/e/1FAIpQLSfjiIZ5Kkv5Dv6yPzQC5V9UFlv8hbITttEcFuxiERqy-aEqsg/viewform";

export const handstandHappyHourSignUp =
  "https://docs.google.com/forms/d/e/1FAIpQLSdOe66KIayk1ddLAEci8nS_lHPjeNCy631DkGFf5z9qHh1KeA/viewform";

import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import { mailToLink, newsletterSignUp } from "../../constants";

const About = () => {
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <div className="aboutImage headerImage"></div>
      <Container fluid style={{ backgroundColor: "#8c9490", color: "#f8f9fa" }}>
        <Row style={{ padding: "50px 30px" }} xs={1} md={2} lg={2}>
          <Col>
            <h4 style={{ paddingBottom: "30px" }}>My Yoga Journey</h4>
            <p>
              Yoga has been a part of my life since I am 12 years old. My first
              ever yoga class actually happened in my living room at home in
              front of the TV with a Nintendo Wii balance board and an animated
              avatar as my teacher. Ever since this day, Yoga has been a crucial
              part of my life to find balance physically and mentally.
            </p>
            <p>
              As I have grown up and become more and more interested in
              engineering, I started to understand my fascination for the rather
              technical way of approaching yoga at the beginning. This interest
              has also led me to practice Ashtanga Yoga for many years at the
              beginning of my yoga journey. In the meantime, I prefer Hatha Yoga
              and Yin Yoga for a better balance.{" "}
            </p>
            <p>
              In 2018, I concluded my first teacher training (200h) in Hatha
              Yoga with{" "}
              <a
                style={{ color: "#282828" }}
                href="https://www.zunayoga.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Zuna Yoga
              </a>{" "}
              (Everett Newell & Katherine Girling) in Bali, Indonesia. As an
              engineer, I especially loved to learn from this training how much
              scientific proof there is today for the benefits on body and mind
              of the ancient yoga practice.
            </p>
            <p>
              Next to Hatha Yoga, I also practice AcroYoga (acrobatic yoga).
              Principles of both Hatha and AcroYoga will always be present in my
              classes as I frequently do partner exercises where we help each
              other into difficult poses and give support. I always try to
              create an atmosphere in my classes that allows to both find ease
              and challenge, especially by learning and exploring new poses. I
              typically hold space in every class for a short workshop-style
              break where we look at a pose in more detail and depth.
            </p>
          </Col>
          <Col>
            <h4 style={{ paddingBottom: "30px" }}>Education</h4>
            <ul>
              <li>
                Ongoing: 300hr Advanced Yoga Teacher Training (2023/2024),{" "}
                <a
                  href="https://www.sanapurna.ch/index.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sanapurna
                </a>
                , Zurich
              </li>
              <li>50hr Yin Yoga & Anatomy TT (2020), Markus Henning, Zurich</li>
              <li>200hr YTT Hatha Yoga (2018), Zuna Yoga, Bali</li>
            </ul>
            <h4 style={{ paddingBottom: "30px", paddingTop: "20px" }}>
              Contact & Resources
            </h4>
            <p>
              Feel free to contact me through{" "}
              <a href={mailToLink} style={{ color: "#282828" }}>
                E-Mail
              </a>{" "}
              if you have questions or would like to know more about classes or
              retreats.
            </p>
            <p>
              If you want to stay up-to-date with what I am doing, I highly
              recommend to sign up for the Newsletter below and/or follow me on{" "}
              <a
                style={{ color: "#282828" }}
                href="https://instagram.com/hatha.yoga.lisa"
              >
                Instagram
              </a>
            </p>
            <p>
              If you are interested in AcroYoga, please check out our{" "}
              <a
                style={{ color: "#282828" }}
                href="https://acroyoga-essentials.com"
              >
                AcroYoga Essentials
              </a>{" "}
              website for classes and video tutorials.
            </p>
            <p>I am looking forward to meeting you on the mat.</p>
            <Button
              variant="secondary"
              onClick={() => {
                window.location = newsletterSignUp;
              }}
            >
              Newsletter
            </Button>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default About;

import React from "react";
import { Container, Row, Card, Accordion, Button } from "react-bootstrap";

import blogposts from "./blogposts";

const Blog = () => {
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <div className="blogImage headerImage"></div>
      <Container
        fluid
        style={{
          backgroundColor: "#3c4464",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row
          className="centeredRow"
          style={{ padding: "30px", width: "700px" }}
        >
          <Accordion
            style={{
              width: "100%",
              color: "#f8f9fa",
            }}
            defaultActiveKey="0"
          >
            {blogposts.map(({ title, date, subtitle, sections }, index) => (
              <Card key={index} style={{ backgroundColor: "#3c4464" }}>
                <Card.Header style={{ backgroundColor: "#282828" }}>
                  <Accordion.Toggle
                    as={Button}
                    style={{ color: "#f8f9fa" }}
                    variant="link"
                    eventKey={JSON.stringify(index)}
                  >
                    {title} - {date}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={JSON.stringify(index)}>
                  <Card.Body>
                    <Card.Subtitle>{subtitle}</Card.Subtitle>
                    {sections.map((section, sectionIndex) => (
                      <Card.Text key={`section-${sectionIndex}`}>
                        {section}
                      </Card.Text>
                    ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Blog;

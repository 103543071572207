import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { CLOSE_NEWSLETTER_DIALOG, NEWSLETTER_SIGN_UP } from "../../actions";

const NewsletterDialog = () => {
  const newsletterDialogVisibility = useSelector(
    state => state.newsletterDialogVisibility
  );
  const busyState = useSelector(state => state.busyState);

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  return (
    <Modal
      show={newsletterDialogVisibility}
      onHide={() => dispatch({ type: CLOSE_NEWSLETTER_DIALOG })}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Newsletter</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Please enter your e-mail address here if you would like to sign up for
          the newsletter. The newsletter is informative and will cover upcoming
          classes, retreats and workshops.
        </p>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              onChange={e => {
                setEmail(e.target.value);
              }}
              type="email"
              placeholder="name@example.com"
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => dispatch({ type: CLOSE_NEWSLETTER_DIALOG })}
          variant="outline-secondard"
        >
          Close
        </Button>
        {!busyState ? (
          <Button
            onClick={() => dispatch({ type: NEWSLETTER_SIGN_UP, email })}
            variant="outline-primary"
          >
            Sign Up
          </Button>
        ) : (
          <Button variant="outline-primary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Sending...
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default NewsletterDialog;

import React from "react";
import { Button } from "react-bootstrap";
import { mailToLink } from "../../constants";
import "./style.scss";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer-container">
        <div className="footer-buttons">
          <Button
            variant="outline-secondary"
            href="https://instagram.com/hatha.yoga.lisa"
          >
            Instagram
          </Button>
        </div>
        <div className="footer-buttons">
          <Button
            variant="outline-secondary"
            href={mailToLink}
          >
            E-Mail
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;

/*
 * action types
 */

export const OPEN_NEWSLETTER_DIALOG = "OPEN_NEWSLETTER_DIALOG";
export const CLOSE_NEWSLETTER_DIALOG = "CLOSE_NEWSLETTER_DIALOG";
export const NEWSLETTER_SIGN_UP = "NEWSLETTER_SIGN_UP";
export const SET_BUSY_STATE = "SET_BUSY_STATE";

/*
 * action creators
 */

export function openNewsletterDialog() {
  return { type: OPEN_NEWSLETTER_DIALOG };
}

export function closeNewsletterDialog() {
  return { type: CLOSE_NEWSLETTER_DIALOG };
}

export function newsletterSignUp(email) {
  return { type: NEWSLETTER_SIGN_UP, email };
}

export function setBusyState(state) {
  return { type: SET_BUSY_STATE, state };
}

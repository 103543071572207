import { put, call } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";

import emailjs from "emailjs-com";

import {
  CLOSE_NEWSLETTER_DIALOG,
  NEWSLETTER_SIGN_UP,
  SET_BUSY_STATE
} from "../actions";

function* sendEmail(action) {
  const { email } = action;

  const params = {
    email
  };

  const serviceID = "service_622w28k";
  const templateID = "template_opybyi9";
  const userID = "i3DJA9ioFrKpWbY1-";

  try {
    yield put({ type: SET_BUSY_STATE, state: true });

    yield call(emailjs.send, serviceID, templateID, params, userID);
    yield put({ type: SET_BUSY_STATE, state: false });

    yield put({ type: CLOSE_NEWSLETTER_DIALOG });
  } catch (err) {
    yield put({ type: SET_BUSY_STATE, state: false });

    console.log(err);
  }
}

function* saga() {
  yield takeLatest(NEWSLETTER_SIGN_UP, sendEmail);
}

export default saga;

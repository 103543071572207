import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";

import OnlineClassesImage from "../../img/online-classes.JPG";
import CardioClassesImage from "../../img/cardio-classes.JPG";

import { Link } from "react-router-dom";

const Videos = () => {
  window.scrollTo(0, 0);

  return (
    <Container
      className="content-container"
      fluid
      style={{ backgroundColor: "#a7ad85" }}
    >
      <Row className="centeredRow" style={{ paddingTop: "50px" }}>
        <h2>Videos</h2>
      </Row>
      <Row className="centeredRow" style={{ paddingTop: "30px" }}>
        <div>
          <p>
            Here you can find a collection of recorded online classes &
            yoga-inspired cardio workouts. Happy practicing!
          </p>
          <ul>
            <li>
              <b>Online Recorded Classes</b> (30-75min): A collection of
              recorded online classes and video series with various topics &
              length. Audio & video quality can vary.
            </li>
            <li>
              <b>Yoga-inspired Cardio Workouts</b> (15min): An experiment on
              combining yoga poses with cardio exercises. Audio & video quality
              are rather low.
            </li>
          </ul>
        </div>
      </Row>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Row
          style={{
            marginTop: "50px",
            paddingBottom: "50px",
            maxWidth: "1000px",
          }}
          lg={2}
        >
          <Col xs={6} md={4}>
            <Link to="/online-classes">
              <Button bsPrefix="homeButton">
                <Image src={OnlineClassesImage} fluid roundedCircle />
                <h5 className="homeTitle">Recorded Online Classes</h5>
              </Button>
            </Link>
          </Col>
          <Col xs={6} md={4}>
            <Link to="/cardio-videos">
              <Button bsPrefix="homeButton">
                <Image src={CardioClassesImage} fluid roundedCircle />
                <h5 className="homeTitle">Yoga-inspired Cardio Workouts</h5>
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Videos;
